import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import { Tabs, Tab } from '@paljs/ui';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Graphql Modules" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <p><strong parentName="p">{`CONTENT`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#features"
          }}>{`Features`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#example-usage"
          }}>{`Example Usage`}</a>
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "#merge-all-modules"
              }}>{`Merge all modules`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "##create-inputsmodulets"
              }}>{`cretae `}<code parentName="a" {...{
                  "className": "language-text"
                }}>{`inputs.module.ts`}</code></a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#create-prismaproviderts"
              }}>{`create `}<code parentName="a" {...{
                  "className": "language-text"
                }}>{`Prisma.provider.ts`}</code></a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#add-select-object-to-args"
              }}>{`Add select object to args`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "/plugins/sdl-inputs"
              }}>{`GraphQL SDL inputs`}</a></li>
          </ul>
        </li>
      </ul>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "features"
      }}>{`Features`}</h2>
      <p>{`Auto generate CRUD system from your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code>{` file.
`}<strong parentName="p">{`Every model will have folder contain 3 files`}</strong></p>
      <ul>
        <li parentName="ul"><strong parentName="li"><em parentName="strong">{`X.module.ts`}</em></strong>{` contain `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`GraphQLModule`}</code>{` for this model.`}</li>
        <li parentName="ul"><strong parentName="li"><em parentName="strong">{`typeDefs.ts`}</em></strong>{` contain graphql types for this model.`}</li>
        <li parentName="ul"><strong parentName="li"><em parentName="strong">{`resolvers.ts`}</em></strong>{` contain 3 queries and 6 mutations:`}
          <ul parentName="li">
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`findUnique`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`findFirst`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`findMany`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`findCount`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`aggregate`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`createOne`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`updateOne`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`upsertOne`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`deleteOne`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`updateMany`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`deleteMany`}</code></li>
          </ul>
        </li>
      </ul>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h3 {...{
        "id": "example-usage"
      }}>{`Example Usage`}</h3>
      <p>{`For more information about Prisma look at they `}<a parentName="p" {...{
          "href": "https://www.prisma.io/docs"
        }}>{`Docs`}</a></p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "prisma"
      }}><pre parentName="div" {...{
          "className": "language-prisma"
        }}><code parentName="pre" {...{
            "className": "language-prisma"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`datasource`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` postgresql`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  url      `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`env`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`"DATABASE_URL"`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  provider `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"postgresql"`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`generator`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` client`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"prisma-client-js"`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`      `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  createdAt`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`now`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  email    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@unique`}</span>{`
  name     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`
  role     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Role`}</span>{`     `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`USER`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  posts    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id        `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`        `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  createdAt `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`now`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  updatedAt `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@updatedAt`}</span>{`
  published `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Boolean`}</span>{`    `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token boolean"
            }}>{`false`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  title     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
  author    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`      `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@relation`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`fields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{`  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`authorId`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` references`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`id`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  authorId  `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`enum`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` Role`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`USER`}</span>{`
  `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`ADMIN`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`After build your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code>{` file all you need to run`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`prisma generate
pal g`}</code></pre></div>
      <ul>
        <li parentName="ul">{`build prisma client`}</li>
        <li parentName="ul">{`auto generate your crud system for more information about `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`pal g`}</code>{` command configurations `}<a parentName="li" {...{
            "href": "/cli/generator"
          }}>{`click here`}</a></li>
      </ul>
      <h4 {...{
        "id": "output-for-user-model"
      }}>{`Output For User Model`}</h4>
      <Tabs mdxType="Tabs">
        <Tab title="typeDefs.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` gql `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'graphql-tag'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`default`}</span>{` gql`}<span parentName="code" {...{
                  "className": "token template-string"
                }}><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span><span parentName="span" {...{
                    "className": "token string"
                  }}>{`
  type User {
    id: Int!
    createdAt: DateTime!
    email: String!
    name: String
    password: String!
  }

  type Query {
    findUniqueUser(where: UserWhereUniqueInput!): User
    findManyUser(
      where: UserWhereInput
      orderBy: UserOrderByInput
      cursor: UserWhereUniqueInput
      skip: Int
      take: Int
    ): [User!]
    findManyUserCount(
      where: UserWhereInput
      orderBy: UserOrderByInput
      cursor: UserWhereUniqueInput
      skip: Int
      take: Int
    ): Int!
  }
  type Mutation {
    createOneUser(data: UserCreateInput!): User!
    updateOneUser(where: UserWhereUniqueInput!, data: UserUpdateInput!): User!
    deleteOneUser(where: UserWhereUniqueInput!): User
    upsertOneUser(where: UserWhereUniqueInput!, create: UserCreateInput!, update: UserUpdateInput!): User
    deleteManyUser(where: UserWhereInput): BatchPayload
    updateManyUser(where: UserWhereInput, data: UserUpdateManyMutationInput): BatchPayload
  }
`}</span><span parentName="span" {...{
                    "className": "token template-punctuation string"
                  }}>{`\``}</span></span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span></code></pre></div>
        </Tab>
        <Tab title="resolvers.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` PrismaProvider `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'../Prisma.provider'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`default`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  Query`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`findUniqueUser`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` injector `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` GraphQLModules`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`Context`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`findUnique`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`findFirstUser`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` injector `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` GraphQLModules`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`Context`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`findFirst`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`findManyUser`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` injector `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` GraphQLModules`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`Context`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`findMany`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`findManyUserCount`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` injector `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` GraphQLModules`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`Context`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`count`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`aggregateUser`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` injector `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` GraphQLModules`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`Context`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`aggregate`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  Mutation`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`createOneUser`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` injector `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` GraphQLModules`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`Context`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`create`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`updateOneUser`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` injector `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` GraphQLModules`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`Context`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`update`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`deleteOneUser`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`async`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` injector `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` GraphQLModules`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`Context`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`await`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`onDelete`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` model`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` where`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`where `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`delete`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`upsertOneUser`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`async`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` injector `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` GraphQLModules`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`Context`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`upsert`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`deleteManyUser`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`async`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` injector `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` GraphQLModules`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`Context`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`await`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`onDelete`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` model`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` where`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`where `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`deleteMany`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`updateManyUser`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` injector `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` GraphQLModules`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`Context`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` injector`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`get`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`PrismaProvider`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`updateMany`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span></code></pre></div>
        </Tab>
        <Tab title="User.module.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` createModule `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'graphql-modules'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` typeDefs `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'./typeDefs'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`
`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` resolvers `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'./resolvers'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` UserModule `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`createModule`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  typeDefs`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  resolvers`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`;`}</span></code></pre></div>
        </Tab>
      </Tabs>
      <h3 {...{
        "id": "merge-all-modules"
      }}>{`Merge all modules`}</h3>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`src/app/application.ts`}</code></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` createApplication `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'graphql-modules'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` InputsModule `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./inputs/inputs.module'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` CommonModule `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./common/common.module'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` addSelect `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./addSelect'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` PrismaProvider `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./Prisma.provider'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` application `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`createApplication`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  modules`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`InputsModule`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` CommonModule`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  providers`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`PrismaProvider`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  middlewares`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'*'`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'*'`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`addSelect`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h3 {...{
        "id": "create-inputsmodulets"
      }}>{`create `}<code parentName="h3" {...{
          "className": "language-text"
        }}>{`inputs.module.ts`}</code></h3>
      <p>{`Create the inputs module to share will all modules to use Prisma and inputs`}</p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`src/app/inputs/inputs.module.ts`}</code></p>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` createModule `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'graphql-modules'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}><span parentName="span" {...{
                "className": "token keyword"
              }}>{`import`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` sdlInputs `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span>{` `}<span parentName="span" {...{
                "className": "token keyword"
              }}>{`from`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`'@paljs/plugins'`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` InputsModule `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`createModule`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Inputs'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  typeDefs`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token function"
              }}>{`sdlInputs`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`,`}</span></span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h3 {...{
        "id": "create-prismaproviderts"
      }}>{`create `}<code parentName="h3" {...{
          "className": "language-text"
        }}>{`Prisma.provider.ts`}</code></h3>
      <p>{`create Prisma instance and add onDelete plugin to it`}</p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`src/app/Prisma.provider.ts`}</code></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` PrismaDelete`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` onDeleteArgs `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/plugins'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` Injectable`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` OnDestroy `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'graphql-modules'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` PrismaClient `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@prisma/client'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

@`}<span parentName="code" {...{
              "className": "token function"
            }}>{`Injectable`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`class`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaProvider`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`extends`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaClient`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`implements`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`OnDestroy`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`constructor`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`super`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`$connect`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`onDestroy`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`void`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`$disconnect`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`async`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`onDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`args`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` onDeleteArgs`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` prismaDelete `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token keyword"
            }}>{`this`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`await`}</span>{` prismaDelete`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`onDelete`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <h2 {...{
        "id": "add-select-object-to-args"
      }}>{`Add select object to args`}</h2>
      <p>{`It's a small tool to convert `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`info: GraphQLResolveInfo`}</code>{` to select object accepted by `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`prisma client`}</code>{` this will give you the best performance because you will just query exactly what you want`}</p>
      <p>{`This middleware is take `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`info`}</code>{` and convert it to Prisma select object and add to resolve args`}</p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`src/app/addSelect.ts`}</code></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` PrismaSelect `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/plugins'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`addSelect`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`context`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` next`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` result `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`PrismaSelect`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`context`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`info`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`value`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`if`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`!`}</span>{`result`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`select `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`||`}</span>{` Object`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`keys`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`result`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`select`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`length `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` `}<span parentName="code" {...{
              "className": "token number"
            }}>{`0`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    context`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`args `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`context`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`args`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`result`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`next`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      